.stuff {
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  margin: 0 0 10rem;

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 720px) {
    gap: 3rem;
    flex-direction: column-reverse !important;
    margin: 0 0 15rem;
  }

  .role {
    margin: 0 0 2rem;
  }
}

.stuff-image {
  flex: 0 0 auto;
  width: 55%;
  text-align: center;

  @media screen and (max-width: 920px) {
    width: 40%;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    padding: 0 1rem;
  }

  .card {
    &.portrait {
      width: 75%;
      margin: 0 auto;
    }
  }
}

.stuff-description {
  position: sticky;
  top: 2rem;
  padding: 10vh 0;

  @media screen and (max-width: 720px) {
    position: initial;
    top: initial;
    padding: 0;
  }

  > h1 {
    margin-bottom: 1rem;
  }
}
