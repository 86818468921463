.contact {
  display: flex;
  align-items: flex-start;
  gap: 7.8rem;

  @media screen and (max-width: 1050px) {
    gap: 6rem;
  }
  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 4rem;
  }
}

.contact-content {
  flex: 1 1 auto;

  > h1 {
    margin: 0 0 3rem;

    @media screen and (max-width: 600px) {
      margin: 0 0 1.5rem;
    }
  }

  > .available {
    margin: 0 0 1.4rem;
  }
}

.contact-form {
  flex: 0 1 auto;
  min-width: 40%;
  padding: 2rem;
  border-radius: 1rem;
  border: var(--field-border);
  box-shadow: var(--card-shadow);
  background: var(--card-background);
  background-blend-mode: overlay;

  @media screen and (max-width: 950px) {
    width: 70%;
    margin: 0 0 4rem;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  > .success {
    position: relative;
    margin: 0 0 2rem;
    padding: 1rem;
    font-size: 1.5rem;
    border-radius: .5rem;
    overflow: hidden;
    background-color: #101d10;
    border: .5px solid #85d58555;
    color: #85d585;
    font-family: monospace;
    font-weight: 600;
    text-align: center;
    text-shadow: 0 0 5px #719e71;
  }
}