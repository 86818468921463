@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Inter-Medium.woff2") format("woff2"), url("../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/Inter-SemiBold.woff2") format("woff2"), url("../fonts/Inter-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
}

@font-face {
    font-family: "Thunder";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/Thunder-SemiBoldLC.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
}
