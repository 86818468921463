::selection {
  background: var(--selection-hexa) /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: var(--selection-hexa) /* Gecko Browsers */
}

h1 {
  font-size: 4.2rem;
  line-height: 5rem;
  font-weight: 700;
  font-family: Thunder;

  @media screen and (max-width: 600px) {
    font-size: 4rem;
    line-height: 4rem;
  }

  &.big {
    font-size: 6rem;
    line-height: normal;
    font-weight: 700;

    @media screen and (max-width: 1140px) {
      font-size: 6rem;
    }
    @media screen and (max-width: 600px) {
      font-size: 5rem;
    }
  }
}

h2 {
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  &.big {
    font-size: 3.75rem;
    line-height: 3.5rem;
    font-weight: 700;
    text-transform: none;
    letter-spacing: -0.05rem;

    @media screen and (max-width: 600px) {
      font-size: 2.25rem;
      line-height: 3rem;
    }
  }
}

h3 {
  font-size: 1.35rem;
  line-height: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

p,
ul {
  margin: 0 0 1rem;
  color: var(--grey-hexa);
  font-size: 1rem;
  line-height: 1.5rem;

  &:last-child {
    margin: 0;
  }

  &.role {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    > .bullet {
      color: var(--light-hexa);
    }
  }
}

ul {
  padding-left: 1.5rem;
}

strong {
  color: var(--light-hexa);
}

figcaption {
  font-size: 1rem;
  line-height: 1.5rem;
}

hr {
  margin: 3rem 0;
  border: 0;
  text-align: center;

  &:before {
    content: "\2022  \2022  \2022";
    opacity: .5;
    font-size: .8rem;
    color: var(--grey-hexa);
  }
}

.clipboard {
  margin-right: .7rem;

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='3.5' width='8' height='11' rx='1' stroke='white'/%3E%3Cpath d='M3 2V1.5C3 0.947715 3.44772 0.5 4 0.5H10.5C11.0523 0.5 11.5 0.947715 11.5 1.5V11C11.5 11.5523 11.0523 12 10.5 12H10' stroke='white'/%3E%3C/svg%3E%0A");
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      color: var(--grey-hexa);
      opacity: .5;
      transform: translate(150%, -75%);
      transition: opacity ease-in-out 100ms;
    }

    &:hover::after {
      opacity: 1;
    }

    &.copied::after {
      content: "COPIED";
      opacity: 1;
      font-size: .5em;
      font-weight: 600;
      color: var(--notification-hexa);
      transform: translate(110%, -35%);
    }
}

a {
  display: inline-block;
  position: relative;
  color: var(--light-hexa);
  text-decoration: none;
  border-bottom: 1px solid rgba(var(--light-rgb), var(--link-border-dimmed-opacity));
  background: transparent;
  transition: all ease-in-out 100ms;
  line-height: normal;

  &:hover {
    border-color: var(--light-hexa);

    &.blank::after {
      opacity: 1;
    }
  }

  &:focus,
  &:active {
    border-color: var(--light-hexa);
  }

  &.blank {
    margin-right: .7rem;

    &::after {
      content: "→";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      color: var(--grey-hexa);
      opacity: .5;
      font-size: .6em;
      transform: translate(110%, -85%) rotate(-45deg);
      transition: opacity ease-in-out 100ms;
    }
  }

  @each $name, $map in $colors {
    &.#{"" + $name} {
      color: map-get($map, "hexa");
      border-color: rgba(map-get($map, "rgb"), var(--link-border-dimmed-opacity));

      &:hover {
        border-color: map-get($map, "hexa");
      }

      &:focus,
      &:active {
        border-color: map-get($map, "hexa");
      }
    }
  }
}

img {
  &.rounded {
    border-radius: 1rem;
  }
}

figure {
  text-align: center;

  > img {
    max-width: 100%;
    height: auto;
  }
}

figcaption {
  color: var(--grey-hexa);
  padding: 1rem;
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .1rem .65rem;
  border-radius: .5rem;
  background-color: rgba(var(--grey-rgb), .1);
  color: var(--grey-hexa);
  font-size: .75em;
  font-weight: 600;
  white-space: nowrap;

  &.ongoing {
    background-color: rgba(var(--ongoing-rgb), .1);
    color: var(--ongoing-hexa);
  }

  &.done {
    background-color: rgba(var(--done-rgb), .1);
    color: var(--done-hexa);
  }
}

button,
.button {
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  gap: .5rem;
  height: 4rem;
  border: 0;
  border: 1px solid var(--light-hexa);
  border-radius: .5rem;
  background: var(--light-hexa);
  color: var(--dark-hexa);
  padding: 0 2.5rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 4rem;
  transition: background ease-in-out 100ms, filter ease-in-out 100ms, box-shadow ease-in-out 100ms;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);

    > .arrow {
      transform: translateX(.25rem);
    }
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--dark-hexa), 0 0 0 4px var(--grey-hexa);
  }

  &:active {
    filter: brightness(80%);
  }

  > .arrow {
    transition: transform ease-in-out 100ms;
    font-size: .9em;
  }
}

.form-element {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  &.daftpunk {
    display: none;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

label {
  font-size: .85rem;
  font-weight: 600;
  color: var(--grey-hexa);
}

input[type="text"],
input[type="email"],
textarea {
  padding: .75rem;
  font-size: 1rem;
  border-radius: .5rem;
  border: var(--field-border);
  transition: box-shadow ease-in-out 100ms;
  background: var(--field-background-hexa);
  font-family: var(--inter-font);
  color: var(--light-hexa);

  &:focus {
    box-shadow: 0 0 0 1px var(--dark-hexa), 0 0 0 4px var(--grey-hexa);
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(var(--grey-rgb), .5);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(var(--grey-rgb), .5);
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(var(--grey-rgb), .5);
  }
}

.card {
  position: relative;
  overflow: hidden;
  border: var(--card-border);
  border-radius: 1rem;
  box-shadow: var(--card-shadow);
  background: var(--card-background);
  background-blend-mode: overlay, normal;
  transition-duration: 100ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate(0) translateZ(-1000px);

  &.no-rounded {
    border-radius: 0;
    background: none;
    border: 0;
  }

  &:hover {
    z-index: 1;
    transition-duration: 100ms;
    box-shadow: var(--card-shadow-hover);
  }

  &:hover,
  &:focus,
  &:active {
    border: var(--card-border);

    &.no-rounded {
      border: 0;
    }
  }

  > .card-content {
    padding: 1.75rem;
  }

  > .card-overlay {
    position: absolute;
    z-index: 2;
    inset: 0;
    text-align: left;
    background-image: linear-gradient(to bottom, rgba(24, 24, 27, .5), transparent 50%);

    > .card-overlay-type {
      margin: 0 0 .35rem;
      color: var(--white-hexa);
      opacity: 0.5;
      font-size: 0.85rem;
      line-height: 1.15rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.45), 0px 0px 1px rgba(0, 0, 0, 0.2);
    }

    .card-title {
      color: var(--white-hexa);
      text-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.85), 0px 1px 1px rgba(0, 0, 0, .3);
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 700;
      text-transform: none;
      letter-spacing: -0.0075em;
    }
  }

  .card-title {
    margin: 0 0 .8rem;
    color: var(--light-hexa);
  }

  img,
  video {
    display: block;
    width: 100%;
    height: auto;
  }

}

.card-glow {
  pointer-events: none;
  position: absolute;
  inset: 0;
  background-image: radial-gradient(circle at 100% -20%, #ffffff11, #ffffff00);
  background-blend-mode: multiply;
}

@keyframes pulse-notification {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(var(--notification-rgb), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(var(--notification-rgb), 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(var(--notification-rgb), 0);
  }
}

.zoom > img {
  max-width: 100%;
  height: auto;
}

.notification {
  display: block;
  border-radius: 50%;
  height: .65rem;
  width: .65rem;
  transform: scale(1);
  background: rgba(var(--notification-rgb), 1);
  box-shadow: 0 0 0 0 rgba(var(--notification-rgb), 1);
  animation: pulse-notification 2s infinite;
}

.available {
  display: inline-flex;
  align-items: center;
  gap: .75rem;
  padding: 1rem 1.25rem;
  border: 0;
  border-radius: 10rem;
  background: rgba(var(--notification-rgb), .15);
  transition: box-shadow 250ms ease-out;
  color: var(--notification-hexa);
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03rem;

  &:hover {
    box-shadow: 0 0 2rem 3px rgba(var(--dark-rgb), 1), 0 0 6rem rgba(var(--notification-rgb), .15);
  }
}

.logos {
  display: flex;
  gap: 1rem 2.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 820px) {
    gap: .5rem 1rem;

    > svg {
      transform: scale(.8);
    }
  }

  @media screen and (max-width: 500px) {
    gap: .5rem;
  }
}

.lets-work-together {
  margin: 0 0 6rem;
  text-align: center;

  > h2 {
    font-size: 1.5rem;
    line-height: 5rem;
    text-transform: none;
    letter-spacing: normal;
  }
}

.testimonials {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  > h2 {
    width: 100%;
    font-size: 1.5rem;
    line-height: 5rem;
    text-transform: none;
    letter-spacing: normal;
    text-align: center;

    > .hooray {
      display: inline-block;
      margin: 0 1rem;
      transform: translateY(-3px);
    }
  }
}

.testimonial {
  flex: 0 1 auto;
  width: calc(33% - 3rem);
  min-width: 13rem;
  text-align: center;

  > .card {
    padding: 1rem;
    height: 100%;
    background: var(--dark-hexa);
  }

  .stars {
    height: 8px;
    margin: 0 0 .75rem;
  }

  .content {
    margin: 0 0 .5rem;
    color: var(--light-hexa);
    font-size: .875rem;
    line-height: 1.2rem;
    font-weight: 500;
    text-wrap: balance;
  }

  .author {
    color: var(--grey-hexa);
    font-size: .75rem;
    font-weight: 600;
    line-height: .85rem;
  }
}

.columns {
  display: flex;
  gap: 8rem;

  > .column {
    flex: 1;
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 3rem;
  }
}

.browser {
  border-radius: .5rem;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);

  > img,
  video {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0;
  }

  &.zoom {
    transition-duration: 100ms;
    transition-property: transform, box-shadow;
    transition-timing-function: ease-out;
    transform: rotate(0) translateZ(-1000px);

    &:hover {
      z-index: 1;
      transition-duration: 100ms;
      box-shadow: var(--card-shadow-hover);
    }
  }
}

.browser-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  padding: 0 .5rem;
  background: rgba(var(--grey-rgb), .2);
}

.browser-buttons {
  flex: 0 0 auto;

  > path {
    fill: rgba(var(--grey-rgb), .2);
  }
}

.browser-url {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 1rem;
  border-radius: .25rem;
  background: rgba(var(--grey-rgb), .1);
  color: rgba(var(--grey-rgb), .8);
  font-size: .5rem;
}

%rotateElements {
  $degs: (-2.5deg, 3deg, -.5deg, 1.5deg, -3deg, 2deg, -2deg, 1.3deg, -4deg, 1.5deg);
  $j: 1;

  @for $i from 1 through 200 {
    &:nth-child(#{$i}) {
      transform: translateZ(-1000px) rotate3d(0, 0, 1, nth($degs, $j));
    }

    @if $j < 10 {
      $j: $j + 1;
    } @else {
      $j: 1;
    }
  }
}

.feed-content > .card,
.work-images-gallery > .work-image,
.browser,
.stuff-image .card {
  @extend %rotateElements;
}

.draggable {
  user-select: none;
  touch-action: none;
}