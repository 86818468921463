.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--title-hexa);
  margin: 0 0 10rem;

  > .available {
    margin: 0 0 2rem;
    font-size: 1rem;
    letter-spacing: 0.05rem;
  }

  > h1.big {
    font-size: 6.5rem;
    line-height: 6.8rem;
    margin: 0 0 2rem;
    text-wrap: balance;

    > span {
      position: relative;
      display: block;
      background:
        linear-gradient(81.31deg, rgba(225, 246, 100, .1) 10%, rgba(42, 208, 202, .1) 20%, rgba(255, 106, 255, .1) 40%, rgba(171, 179, 252, .1) 60%, rgba(93, 247, 164, .1) 70%, rgba(88, 196, 246, .1) 80%),
        rgba(255, 255, 255, .85);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      &:last-child {
        top: -.5rem;
      }
    }
  }

  > .tagline {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: -0.05rem;
    margin: 0 0 3rem;
    text-wrap: balance;

    > span {
      position: relative;
      display: inline-block;
      background:
        linear-gradient(81.31deg, rgba(225, 246, 100, .2) 10%, rgba(42, 208, 202, .2) 20%, rgba(255, 106, 255, .2) 40%, rgba(171, 179, 252, .2) 60%, rgba(93, 247, 164, .2) 70%, rgba(88, 196, 246, .2) 80%),
        rgba(255, 255, 255, .65);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      &:last-child {
        top: -.5rem;
      }
    }
  }

  > h1, .tagline {
    .intro-highlight {
      background-image: url("/assets/img/gradient-noise.svg");
      -webkit-background-clip: text;
      background-clip: text;
      background-size: cover;
      -webkit-text-fill-color: transparent;
      transform: translateZ(0);
    }

    .intro-highlight-outline {
      background: radial-gradient(55.11% 415.11% at 36.64% -15.11%, #2AD0CA 0%, #E1F664 22.92%, #FEB0FE 46.88%, #ABB3FC 68.23%, #5DF7A4 87.5%, #58C4F6 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: var(--title-dark-hexa);
      -webkit-text-stroke: 2.5px transparent;
    }
  }

  @media screen and (max-width: 1350px) {
    margin: -2rem 0 6rem;

    > h1.big {
      font-size: 5rem;
      line-height: 5.2rem;

      .intro-highlight-outline {
        -webkit-text-stroke: 2.2px transparent;
      }
    }

    .tagline {
      font-size: 1.65rem;
      line-height: 2rem;
    }
  }

  @media screen and (max-width: 820px) {
    margin: -2rem 0 4rem;

    > h1.big {
      margin: 0 0 1rem;
    }
  }

  @media screen and (max-width: 720px) {
    margin: 0rem 0 4rem;
  }

  .logos {
    opacity: .7;
    transform: scale(1);

    > svg:nth-child(1) path {
      fill: #b7b9ab;
    }

    > svg:nth-child(2) path {
      fill: #9db6b3;
    }

    > svg:nth-child(3) path {
      fill: #bba5bf;
    }

    > svg:nth-child(4) path {
      fill: #b0aec1;
    }

    > svg:nth-child(5) path {
      fill: #a2b7bd;
    }

    > svg:nth-child(6) path {
      fill: #a1b5bf;
    }
  }
}

.testimonials {
  margin: -10rem 0 12rem;

  @media screen and (max-width: 600px) {
    margin: -2rem 0 10rem;

    > .testimonial {
      width: auto;
    }
  }
}

.work {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 0 20rem;

  @media screen and (max-width: 600px) {
    margin: 0 0 10rem;
  }

  h1 {
    margin: 0 0 1rem -.15rem;
  }

  h2 {
    margin: 0 0 .75rem;
  }

  p + h2 {
    margin: 3rem 0 .75rem;
  }

  .role + p {
    margin-top: 3rem;
  }

  @each $name, $map in $colors {
    &.#{"" + $name} {
      .role {
        color: var(--grey-hexa);

        .bullet {
          color: map-get($map, "hexa");
        }
      }
    }
  }
}

.work-image,
.browser {
  max-width: 100%;
  height: auto;
  margin: 0;

  &:not(.card) {
    filter: var(--card-shadow-filter);
  }
}


@media screen and (min-width: 900px) {
  .card.case-study {
    width: 80%;
    margin: 1rem 0 0 ;
  }
}

.work-images-gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > .work-image {
    width: 50%;
  }

  &.three-columns > .work-image {
    width: 33%;
  }

  @media screen and (max-width: 900px) {
    display: block;
    text-align: center;

    > .work-image {
      width: auto;
      max-width: 100%;
    }
  }
}

.work-description-images {
  display: flex;
  align-items: flex-start;
  gap: 6em;

  > .work-description {
    position: sticky;
    flex: 1 1 auto;
    top: 6rem;
    padding-top: 2rem;

    @media screen and (max-width: 900px) {
      position: initial;
      top: initial;
      padding: 0;
    }
  }

  > .work-side-images {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    width: 55%;

    > .work-image > img {
      max-width: 100%;
      height: auto;
    }

    > .work-images-gallery {
      display: block;
      text-align: center;

      > .work-image {
        width: auto;
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    gap: 4em;

    > .work-side-images {
      flex: 1 1 auto;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }
}

.hr-oldies {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35vh 0;
  color: var(--oldies-hexa);
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .03rem;

  .arrow {
    display: block;
    margin: .5rem 0 0;
  }
}

.case-study {
  margin: 0 0 10rem;

  > .case-study-header-image {
    margin: 0 0 5rem;
  }

  > .case-study-above-title {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.05rem;
    margin: 0 0 1rem;
  }

  h1 {
    margin: 0 0 4rem;
  }

  h2 {
    margin: 3rem 0 .75rem
  }

  @media screen and (max-width: 600px) {
    margin: 0 0 4rem;

    h1 {
      margin: 0 0 1.5rem;
    }
  }

  figure {
    max-width: 100%;
    margin: 2rem 0 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}