.feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8rem;
  margin: 0 0 12rem;
}

.feed-header {
  text-align: center;

  > h1 {
    font-size: 6.5rem;
    line-height: 8rem;
  }

  > p {
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0;
  }
}

.feed-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 70%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  > .card {
    width: 100%;
    height: auto;
    margin: 0;

    &.half {
      width: 50%;
    }

    &.open:hover::after {
      transform: translate(-50%, -50%) scale(1.4);
      background: rgba(var(--white-rgb), .9);
    }

    &.open::after {
      content: 'Click & Play →';
      position: absolute;
      display: block;
      z-index: 10;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(var(--white-rgb), .5);
      font-size: 1.5rem;
      color: var(--dark-hexa);
      font-weight: 600;
      border: .5px solid rgba(var(--dark-rgb), .2);
      padding: .25rem .5rem;
      border-radius: .5rem;
      box-shadow: var(--card-shadow);
      transition: transform 100ms ease-in-out, background 100ms ease-in-out;

      @media screen and (max-width: 800px) {
        font-size: 1rem;
      }
    }
  }
}