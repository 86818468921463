.about-me {
  display: flex;
  gap: 8rem;
  margin: 0 0 10rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
  @media screen and (max-width: 600px) {
    margin: 0 0 6rem;
  }
}


.about-me-cards-container {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    min-height: 600px;
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    > .draggable {
      position: relative !important;
      top: auto !important;
      right: auto !important;
      bottom: auto !important;
      left: auto !important;
      max-width: 100% !important;;
    }
  }

  > .draggable {
    position: absolute;
    max-width: 60%;
    width: auto;

    &:hover > .card:hover {
      z-index: auto;
    }

    &:nth-child(1) {
      top: 0px;
      left: 0px;

      > .card { transform: rotate(-4deg); }
    }
    &:nth-child(2) {
      > .card { transform: rotate(2deg); }
    }
    &:nth-child(3) {
      top: 55%;
      left: -5%;

      > .card { transform: rotate(-5deg); }
    }
    &:nth-child(4) {
      bottom: -5%;
      left: 0px;

      > .card { transform: rotate(3deg); }
    }
    &:nth-child(5) {
      top: 40%;
      right: -10%;

      > .card { transform: rotate(5deg); }
    }
    &:nth-child(6) {
      bottom: 5%;
      right: 5%;

      > .card { transform: rotate(-2deg); }
    }
  }
}

.about-me-content {
  flex: 1;
  gap: 2rem;
  text-wrap: balance;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  > h1 {
    margin: 0 0 1.5rem;
  }

  > p {
    margin: 0 0 1.5rem;
  }

  .about-me-highlight {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -1.5rem;
      top: 0;
      bottom: 0;
      background: var(--grey-lighter-hexa);
      width: .25rem;

      @media screen and (max-width: 720px) {
        left: -.75rem;
        width: .15rem;
      }
    }

    + .about-me-highlight::before {
      top: -1.5rem;
    }
  }
}

.about-latest-work {
  margin: 0 0 12rem;
  text-align: center;

  @media screen and (max-width: 600px) {
    margin: 0 0 6rem;
  }

  > h1 {
    margin: 0 0 2.5rem;
  }
}

.about-latest-work-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  > .about-latest-work-card {
    flex: 1 0 auto;
    width: calc(33% - 1rem);
    max-width: calc(33% - 1rem);
    text-align: center;
    aspect-ratio: 13 / 9;

    > img,
    > video {
      display: block;
      position: absolute;
      width: auto;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    width: 75%;

    > .about-latest-work-card {
      width: 100%;
      max-width: 100%;
      aspect-ratio: none;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}