html {
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

body {
  min-height: calc(100vh - 10rem);
  max-width: 1200px;
  margin: 10rem auto 0;
  background: radial-gradient(80% 50rem at 85% -20rem, var(--top-gradient-hexa) 0%, var(--dark-hexa) 100%), var(--dark-hexa);
  color: var(--light-hexa);
  font-family: var(--inter-font);
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 720px) {
    margin: 8rem auto 0;
  }
}

main {
  min-height: calc(100vh - 10rem - 7rem);
  padding: 0 3rem;

  @media screen and (max-width: 720px) {
    min-height: calc(100vh - 8rem - 7rem);
    padding: 0 1.5rem;
  }
}

header {
  position: fixed;
  width: 100%;
  top: 1rem;
  left: 0;
  z-index: 11;

  &.headroom {
    will-change: transform;
    transition: transform 200ms ease-in-out, top 200ms ease-in-out, background-color 200ms ease-in-out;
  }

  &.headroom--pinned {
      transform: translateZ(1000px) translateY(0%);

      &.headroom--not-top {
        top: 0;
        background-color: rgba(var(--dark-rgb), .75);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
      }
  }

  &.headroom--unpinned {
      transform: translateZ(1000px) translateY(-120%);
  }

  > .container {
    display: flex;
    max-width: 1200px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1rem 3rem;

    @media screen and (max-width: 720px) {
      padding: .5rem 1.5rem;
    }
    @media screen and (max-width: 400px) {
      padding: .5rem 1rem;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 2em;
  text-decoration: none;
  border: 0;
  font-family: Thunder;

  &:hover {
    .sunglasses {
      animation: pulse 1s linear infinite;
    }

    .logo-dimmed {
      color: var(--light-hexa);
    }
  }

  > span {
    position: relative;
    top: .15rem;
  }

  @media screen and (max-width: 800px) {
    .logo-dimmed {
      display: none;
    }
  }
  @media screen and (max-width: 650px) {
    > span {
      display: none;
    }
  }
}

.logo-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;

  @media screen and (max-width: 400px) {
    width: 2rem;
    height: 2rem;
  }

  &.sunglasses {
    @keyframes pulse {
      from {
        transform: rotate(0) scale3d(1, 1, 1);
      }

      50% {
        transform: rotate(180deg) scale3d(1.2, 1.2, 1.2);
      }

      to {
        transform: rotate(360deg) scale3d(1, 1, 1);
      }
    }

    transition: opacity ease-in-out 100ms;
  }
}

.logo-dimmed {
  color: var(--grey-hexa);
  transition: color ease-in-out 100ms;
}

nav {
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 650px) {
    gap: .5rem;
  }
  @media screen and (max-width: 400px) {
    gap: 0;
  }
}

.nav-item {
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: var(--top-gradient-hexa);
  }

  @media screen and (max-width: 900px) {
    padding: .5rem .75rem;
    font-size: .9rem;
  }
  @media screen and (max-width: 400px) {
    padding: .5rem .5rem;
  }

  &.active {
    background: var(--top-gradient-hexa);
  }
}

footer {
  margin: 0 auto;
  padding: 3rem;
  color: var(--grey-hexa);

  strong {
    font-weight: 700;
    color: inherit;
  }
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1.5rem;
  font-size: .85rem;
  line-height: 1.15rem;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
  }
  @media screen and (max-width: 450px) {
    text-align: center;
  }
}

.footer-link {
  margin: 0 0 0 1rem;
  border-bottom-color: transparent;
  color: var(--grey-hexa);

  &:hover {
    border-bottom-color: var(--grey-hexa);
  }

  @media screen and (max-width: 720px) {
    margin: 1rem;
  }
}

.footnotes {
  padding: 1.5rem 0 0;
  border-top: .5px solid rgba(var(--grey-rgb), .2);

  a.blank {
    margin-right: 0.4rem;
  }

  > p {
    margin: 0 0 0.35rem;
    font-size: .7rem;
    line-height: 1.2rem;
  }
}

.medium-zoom--opened .medium-zoom-overlay {
  z-index: 12;
}

img.medium-zoom-image {
  transition: transform 300ms cubic-bezier(0.2, 0, 0.2, 1),
    width 300ms cubic-bezier(0.2, 0, 0.2, 1),
    height 300ms cubic-bezier(0.2, 0, 0.2, 1) !important;
}

img.medium-zoom-image--opened {
  z-index: 13;
  border-radius: 0;
  will-change: transform, width, height;
}