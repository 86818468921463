:root {
  --dark-hexa: #18181b;
  --dark-rgb: 24, 24, 27;
  --light-hexa: #ffffff;
  --light-rgb: 255, 255, 255;
  --white-hexa: #ffffff;
  --white-rgb: 255, 255, 255;
  --grey-hexa: #ababbc;
  --grey-rgb: 171, 171, 188;
  --grey-lighter-hexa: #d4d4e8;
  --grey-lighter-rgb: 212, 212, 232;
  --grey-darker-hexa: #76768d;
  --grey-darker-rgb: 118, 118, 141;
  --top-gradient-hexa: #3a3a48;
  --title-dark-hexa: #1a1a1e;
  --selection-hexa: rgba(var(--grey-rgb), .2);
  --field-background-hexa: #18181b;
  --card-shadow: 0 2rem 3rem -1.5rem rgba(0, 0, 0, .8), 0 -.1rem .1rem -.25rem rgba(0, 0, 0, .9);
  --card-shadow-filter: drop-shadow(0 1rem 1rem rgba(0, 0, 0, .3)) drop-shadow(0 -.1rem .1rem rgba(0, 0, 0, .2));
  --card-shadow-hover: 0 1rem 4rem -.5rem rgba(0, 0, 0, .9), 0 -.1rem .2rem -.25rem rgba(0, 0, 0, .5);
  --card-background: rgba(var(--grey-darker-rgb), 0.05);
  --card-border: .5px solid rgba(var(--grey-rgb), 0.15);
  --field-border: .5px solid rgba(var(--grey-lighter-rgb), 0.1);
  --notification-hexa: #00f0bb;
  --notification-rgb: 0, 240, 187;
  --done-hexa: #00f0bb;
  --done-rgb: 0, 240, 187;
  --ongoing-hexa: #d73885;
  --ongoing-rgb: 215, 56, 133;
  --acs-hexa: #6b7ee9;
  --acs-rgb: 107, 126, 233;
  --oldies-hexa: #ffcb00;
  --oldies-rgb: 255, 203, 0;

  // Common variables
  --inter-font: Inter, -apple-system, BlinkMacSystemFont, Helvetica, Roboto, Arial, sans-serif;
  --link-border-dimmed-opacity: .5;
  --cursorX: 0;

  // Common colors
  --x-hexa: #1da1f2;
  --x-rgb: 29, 161, 242;
  --mastodon-hexa: #6364ff;
  --mastodon-rgb: 99, 100, 255;
  --dribbble-hexa: #ea4c89;
  --dribbble-rgb: 234, 76, 137;
  --soundcloud-hexa: #f26f23;
  --soundcloud-rgb: 242, 111, 35;
  --tuleap-hexa: #ff8f00;
  --tuleap-rgb: 255, 143, 0;
  --rifflicks-hexa: #e00e0e;
  --rifflicks-rgb: 224, 14, 14;
  --glean-hexa: #70ab23;
  --glean-rgb: 112, 171, 35;
  --hifi-tuner-hexa: #a5a5a5;
  --hifi-tuner-rgb: 165, 165, 165;
}

$colors: (
  grey: (
    hexa: var(--grey-hexa),
    rgb: var(--grey-rgb)
  ),
  x: (
    hexa: var(--x-hexa),
    rgb: var(--x-rgb)
  ),
  mastodon: (
    hexa: var(--mastodon-hexa),
    rgb: var(--mastodon-rgb)
  ),
  dribbble: (
    hexa: var(--dribbble-hexa),
    rgb: var(--dribbble-rgb)
  ),
  soundcloud: (
    hexa: var(--soundcloud-hexa),
    rgb: var(--soundcloud-rgb)
  ),
  tuleap: (
    hexa: var(--tuleap-hexa),
    rgb: var(--tuleap-rgb)
  ),
  acs: (
    hexa: var(--acs-hexa),
    rgb: var(--acs-rgb)
  ),
  rifflicks: (
    hexa: var(--rifflicks-hexa),
    rgb: var(--rifflicks-rgb)
  ),
  glean: (
    hexa: var(--glean-hexa),
    rgb: var(--glean-rgb)
  ),
  hifi-tuner: (
    hexa: var(--hifi-tuner-hexa),
    rgb: var(--hifi-tuner-rgb)
  ),
);